import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import Image from "next/image";
import Link from "next/link";
import {footerLinks, quicklinks} from "@/components/UI/Footer/constants";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";

function Footer() {
  const [hide, setHide] = useState(true);
  useEffect(() => {
    if (window.location.href.includes("teaching-non-teaching-jobs-near-me"))
      setHide(false);
  }, []);

  const site_url = process?.env?.NEXT_PUBLIC_CANONICAL_DOMAIN;
  const cdn_url = process?.env?.NEXT_PUBLIC_CDN;

  return (
    <>
      <Box
        component="footer"
        sx={{
          "@media(max-width:767px)": {
            background: "#FFFFFF",
            boxShadow: "0px 3px 16px rgba(0, 0, 0, 0.1607843137)",
            position: "fixed",
            bottom: 0,
            padding: "12px",
            whiteSpace: "nowrap",
            display: "flex",
            alignItems: "center",
            justifyContent: "space-evenly",
            width: "100%",
            zIndex: "99",
            display: "none",
            "& .MuiBox-root": {
              position: "relative",
              top: "unset",
              bottom: "unset",
              left: "unset",
              fontSize: "13px",
              padding: "5px 12px",
              ".MuiSvgIcon-root": {
                fontSize: "18px",
              },
            },
          },
        }}
      >
        <Box
          sx={{
            display: "block",
            textAlign: "center",
            position: "fixed",
            left: "20px",
            bottom: "30px",
            zIndex: "99",
            background: " #FFFFFF",
            boxShadow: "0px 3px 16px #00000029",
            border: "1px solid #F6B333",
            borderRadius: "17px",
            fontSize: "16px",
            p: "5px 16px",
            a: {
              color: "#1C1B1B",
              textDecoration: "none",
            },
          }}
        >
          <Link
            href="https://api.whatsapp.com/send?phone=919147423688&text=Hi"
            target="_blank"
            rel="nofollow"
            style={{ display: "flex", alignItems: "center" }}
          >
            <i
              className="icon-whatsapp"
              style={{ color: "#45B549", marginRight: "4px", fontSize: "16px" }}
            />
            Chat <span className="d-none d-md-inline">&nbsp;with us</span>
          </Link>
        </Box>
        <Box
          sx={{
            background: "#FFFFFF",
            boxShadow: "0px 4px 8px #1C1B1B14",
            border: "1px solid #F6B333",
            borderRadius: "17px",
            fontSize: "16px",
            p: "5px 16px",
            a: {
              color: "#555555",
            },
            "@media(min-width: 768px)": {
              display: "none",
            },
          }}
        >
          <Link
            href="https://calendly.com/connectwithjobsineducation/book-a-demo-with-jobs-in-education?month=2023-06"
            target="_blank"
            rel="nofollow"
            style={{ display: "flex", alignItems: "center" }}
          >
            <i
              className="icon-date"
              style={{ color: "#666", marginRight: "4px", fontSize: "16px" }}
            />
            Book a Demo
          </Link>
        </Box>
        <Box
          sx={{
            display: "block",
            textAlign: "center",
            position: "fixed",
            left: "20px",
            bottom: "80px",
            zIndex: "99",
            background: "#FFFFFF",
            boxShadow: "0px 3px 16px #00000029",
            border: "1px solid #F6B333",
            borderRadius: "17px",
            fontSize: "16px",
            padding: "5px 16px",
            a: {
              color: "#1C1B1B",
              textDecoration: "none",
            },
          }}
        >
          <Typography
            sx={{
              background: "#2A5798",
              borderRadius: "8px",
              fontSize: "12px",
              color: "#fff",
              position: "absolute",
              right: "18px",
              top: "-10px",
              p: "0px 8px",
            }}
          >
            NEW
          </Typography>
          <Link
            href="/online-courses"
            target="_blank"
            style={{ display: "flex", alignItems: "center" }}
          >
            <i
              className="icon-online-course"
              style={{ color: "#666", marginRight: "4px", fontSize: "16px" }}
            />
            Online Courses
          </Link>
        </Box>
      </Box>

      {hide && (
        <>
          <Box
            sx={{
              pt: "30px",
              pb: "30px",
              background:"#F5F5F5",
              position: "relative",
            }}
          >
            <Container
              maxWidth="xl"
              sx={{
                "& .MuiListItem-root": {
                  width: "auto",
                  p: 0,
                  a: {
                    textDecoration: "none",
                    color: "#1C1B1B",
                    display: "flex",
                    fontSize:{xs:"14px", md:"16px"},
                  },
                },
              }}
            >
              <Grid container spacing={2}>
                <Grid item xs={12} sm={12} md={6} lg={6} sx={{textAlign:{xs:"center", md:"left"}, "& img":{height:{xs:"60px", md:"75px"}, width:"auto"}}}>
                  <Link href="/" style={{display:"block"}}>
                    <Image
                      src="/logo.svg"
                      alt="Jobs in Education "
                      title="Jobs in Education"
                      width={226}
                      height={75}
                      loading="lazy"
                    />
                  </Link>
                  <List
                    sx={{
                      display: "flex",
                      columnGap: ".5rem",
                      flexWrap: "wrap",
                      listStyle: "none",
                      justifyContent:{xs:"center", md:"left"},
                      mt:{xs:2, md:3},
                      paddingTop:{xs:2, md:.5},
                      paddingBottom:{xs:2, md:.5},
                      borderTop:{xs:"2px solid #fff", md:0},
                      borderBottom:{xs:"2px solid #fff", md:0}
                    }}
                  >
                    {Boolean(footerLinks && Array.isArray(footerLinks) && footerLinks?.length > 0) && footerLinks.map((data, i) => (
                      <ListItem key={i}>
                        <Link href={data.href}>{data.value}</Link>
                      </ListItem>
                    ))}
                  </List>
                  <Box sx={{display:{xs:"none", md:"block"}}}>
                    <Link
                      href="/partnership"
                      rel="noreferrer"
                      aria-label="Partnership with EducationWorld"
                      style={{
                        fontSize: "14px",
                        color:"#1C1B1B",
                        marginTop:"8px",
                        display:"block",
                      }}
                    >
                      Partnership with EducationWorld
                    </Link>
                  </Box>
                </Grid>

                <Grid item xs={12} sm={12} md={6} lg={6} sx={{"& .MuiList-root":{columnGap:{xs:".25rem", md:"1rem"}}}}>
                  <Box sx={{borderBottom:{xs:"2px solid #fff", md:0}, paddingBottom:{xs:1, md:0}, display:{xs:"flex",md:"block"}, justifyContent:"space-between", alignItems:"center"}}>
                    <List
                      sx={{
                        display: "flex",
                        columnGap: "1rem",
                        flexWrap: "wrap",
                        fontSize:"24px",
                        justifyContent: {
                          xs: "center",
                          sm: "center",
                          md: "flex-end",
                        },
                        listStyle: "none",
                        "& span":{
                          fontSize:"28px",
                          color:"#555555",
                        }
                      }}
                    >
                      <ListItem>
                        <Link
                          href="https://www.linkedin.com/company/jobsineducation/"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Jobs in Education on Linkedin"
                        >
                          <span className="icon-linkedine"></span>
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          href="https://www.facebook.com/jobsinedu"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Jobs in Education on Facebook"
                        >
                          <span className="icon-facebook"></span>
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          href="https://www.instagram.com/jobsinedu/"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Jobs in Education on Instagram"
                        >
                          <span className="icon-instagram"></span>
                        </Link>
                      </ListItem>
                      <ListItem >
                        <Link
                          href="https://twitter.com/jobinedu"
                          target="_blank"
                          rel="noreferrer"
                          aria-label="Jobs in Education on Twitter"
                        >
                          <span className="icon-twitter"></span>
                        </Link>
                      </ListItem>

                    </List>
                    <List sx={{
                        display: "flex",
                        columnGap: "1rem",
                        flexWrap: "wrap",
                        fontSize:"24px",
                        justifyContent: {
                          xs: "center",
                          sm: "center",
                          md: "flex-end",
                        },
                        listStyle: "none",
                      }}>
                    <ListItem>
                        <Link
                          href="https://play.google.com/store/apps/details?id=com.jie.ajpl"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Image
                            src={cdn_url + `/jie/web/images/footer/PlayStore1.png`}
                            alt="Jobs in Education Google Play"
                            title="Jobs in Education Google Play"
                            width={132}
                            height={40}
                            loading="lazy"
                            style={{ width: "auto", height: "30px" }}
                          />
                        </Link>
                      </ListItem>
                      <ListItem>
                        <Link
                          href="https://apps.apple.com/in/app/jobs-in-education/id6444102426"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <Image
                            src={cdn_url + `/jie/web/images/footer/AppleStore1.png`}
                            alt="Jobs in Education Apple Store"
                            title="Jobs in Education Apple Store"
                            width={117}
                            height={40}
                            loading="lazy"
                            style={{ width: "auto", height: "30px" }}
                          />
                        </Link>
                      </ListItem>
                    </List>
                  </Box>
                  <Box sx={{display:{xs:"block", md:"none"}}}>
                    <Link
                      href="/partnership"
                      rel="noreferrer"
                      aria-label="Partnership with EducationWorld"
                      style={{
                        fontSize: "18px",
                        color:"#1C1B1B",
                        marginTop:"16px",
                        display:"block",
                        fontWeight:500,
                      }}
                    >
                      Partnership with EducationWorld <span className="icon-forward" style={{fontSize:"16px"}}></span>
                    </Link>
                  </Box>
                  <List
                    sx={{
                      display: "flex",
                      columnGap: "1rem",
                      flexWrap: "wrap",
                      justifyContent: {
                        xs: "left",
                        md: "flex-end",
                      },
                      listStyle: "none",
                      mt:{xs:1, md:3},
                      a: {
                        fontSize:{xs:"12px", md:"16px"},
                      }
                    }}
                  >
                    {Boolean(quicklinks && Array.isArray(quicklinks) && quicklinks?.length > 0) && quicklinks.map((data, i) => (
                      <ListItem key={i} sx={{margin:{xs:"10px 0"}}}>
                        <Link href={data.href}>{data.value}</Link>
                      </ListItem>
                    ))}
                  </List>
                  <Box
                    sx={{
                      color: "#1c1b1b",
                      fontSize: "13px",
                      lineHeight: "20px",
                      marginTop:"8px",
                      textAlign:{xs:"left", md:"right"},
                    }}
                  >
                    Copyright &copy; {new Date().getFullYear()} |{" "}
                    <Link href="/">www.jobsineducation.net</Link>
                  </Box>
                </Grid>
              </Grid>
            </Container>
          </Box>
        </>
      )}
    </>
  );
}

export default Footer;
