
export const footerLinks = [
  {
    value: "About Us",
    href: "/about",
  },
  {
    value: "Contact",
    href: "/contact",
  },
  {
    value: "Blog",
    href: "/blog",
  },
  {
    value: "Education Jobs",
    href: "/job-details/apply/1",
  },
  {
    value: "Sitemap",
    href: "/education-sitemap",
  },
];

export const quicklinks = [
  {
    value: "Privacy Policy",
    href: "/privacy",
  },
  {
    value: "Terms & Conditions",
    href: "/terms",
  },
  {
    value: "Refund & Cancellation Policy",
    href: "/refund",
  },
]